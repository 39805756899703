<template>
  <div class="content">
    <p>
      ethereum: <b>0x484Af9535b06AD3614f68e1119f6E928526Dc543</b><br>
      bitcoin: <b>bc1q5t4nv75egsx770neleade5n9awaa7p9hu5tg5d</b><br>
      trc-20: <b>TAZ2vZ7RN75j4XBYwSu2KaFaLkAVZpVyoU</b><br><br>

      <a href="https://patreon.com/flakersenradio">patreon</a>
    </p>
  </div>
</template>

<script>

export default {
  name: 'DonateComponent',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
a {
  color: #000;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer
}
</style>