<template>
  <div class="content">
    <p>
      merhaba! biz <b>flakersen</b>. <b>2023</b> yılında bir arkadaş grubu tarafından kurulduk. isteğimiz şimdilerde kaybolmak üzere
      olan <b>internet radyoculuğunu canlandırabilmek</b> ve birçok kültürden ve türden müziği sizlere ulaştırabilmek.
      dileklerinizi “<b>iletişim</b>” kısmından mail adresimize iletebilirsiniz. <br><br>

      sevgiler.
    </p>
  </div>
</template>

<script>

export default {
  name: 'IndexComponent',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>