<template>
  <div class="content">
    <p>
      mail adresimiz: <br>
      <b><u>flakersen@gmail.com</u></b><br><br>

      her türlü düşüncenizi, önerilerinizi veya şikayetlerinizi yukarıdaki mail adresinden bizlere ulaştırabilirsiniz.
    </p>
  </div>
</template>

<script>
export default {
  name: 'ContactComponent',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
